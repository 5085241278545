import React from 'react';
import mergeProps from '../util/merge-props';
import { Twitter, Facebook, Instagram } from 'react-feather';
import styles from './footer.module.scss';

const Footer = props => (
  <footer {...mergeProps({ className: styles.Footer }, props)}>
    <a href="https://twitter.com" title="Twitter">
      <Twitter size="30" />
    </a>
    <a href="https://facebook.com" title="Facebook">
      <Facebook size="30" />
    </a>
    <a href="https://instagram.com" title="Instagram">
      <Instagram size="30" />
    </a>
  </footer>
);

export default Footer;
