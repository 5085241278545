import { classNames } from "react-extras"

export default function mergeProps(source, target) {
  const result = {
    ...source,
    ...target,
    className: classNames(
      ...[
        typeof source.className !== "undefined" && source.className,
        typeof target.className !== "undefined" && target.className,
      ].filter(Boolean)
    ),
    style:
      source.style || target.style
        ? {
            ...source.style,
            ...target.style,
          }
        : undefined,
  }

  return result
}
